import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { ServerAnalyticsInterface } from '../../../../../@types/build';
import {
	LocationFieldsFragment,
	Maybe,
	ProductViewEnum,
	SearchVariantFieldsFragment,
	TrackSearchResultClickMutation,
	TrackSearchResultClickMutationVariables
} from '../../../__generated__/graphql-client-types';
import { FEATURE_FLAGS } from '../../../constants/general';
import { ImpressionsContext } from '../../../contexts/impressions/impressions.context';
import { SearchVariantContext } from '../../../contexts/search/search-variant.context';
import { ANALYTICS_PAGE_NAMES, AnalyticsEvent } from '../../../helpers/analytics/analytics.helper';
import { TrackedEvent } from '../../../helpers/analytics/event-types';
import { GTMEventWrapper, GTMFindingMethodEvent } from '../../../helpers/analytics/gtm/gtm-types';
import { setUrlParam } from '../../../helpers/general-helper/general-helper';
import { ADD_TO_CART_BUTTON_TYPE } from '../../../helpers/product-helper/add-to-cart-button/add-to-cart-button.helper';
import { getFindingMethodEventByDropType } from '../../../helpers/search-helper/search-analytics.helper';
import { isAppointmentOnlySearchProduct } from '../../../helpers/search-helper/search-product-helper';
import { useAddToCartButton } from '../../../hooks/add-to-cart/add-to-cart-button.hooks';
import { useAddToCartAction } from '../../../hooks/add-to-cart/add-to-cart.hooks';
import { useCustomer } from '../../../hooks/apollo/customer/customer.hooks';
import { UseSearchResultsPayload } from '../../../hooks/apollo/search/search.hooks';
import { useFeature } from '../../../hooks/features/features.hooks';
import { useNavigation } from '../../../hooks/navigation/navigation.hooks';
import { useNotification } from '../../../hooks/notification/notification.hooks';
import { TRACK_SEARCH_RESULT_CLICK } from '../../../queries/search/search-tracking.queries';
import { DropType, SearchProduct } from '../../../types/search.types';
import { ClickableElement } from '../../buttons';
import { Link } from '../../common-components/link/link.component';
import { Popover } from '../../common-components/popover/popover.component';
import { CloudinaryImage } from '../../images/cloudinary-image/cloudinary-image.component';
import { AddToCartButtonFactory } from '../../product-components/add-to-cart-button/add-to-cart-button.component';
import { CompareButton } from '../../product-components/compare-button/compare-button.component';
import { Rating } from '../../product-components/rating/rating.component';
import { quickShipIcon } from '../../product-components/shipping-eligibility/shipping-eligibility.css';
import { ChevronDownIcon, QuickShipIcon, WarningIcon } from '../../svg/icons.component';
import { SearchProductCardPrice } from '../search-product-card-price/search-product-card-price.component';
import { SearchVariantSelector } from '../search-variant-selector/search-variant-selector.component';
import { cardShadow } from './search-product-card.css';

function createAnalyticsEvents(...gtmEvents: (GTMEventWrapper | null | undefined)[]): AnalyticsEvent[] {
	return gtmEvents.filter((evt: GTMEventWrapper): evt is NonNullable<GTMEventWrapper> => Boolean(evt)).map((evt) => ({ data: evt }));
}

export type SearchProductCardProps = {
	product: SearchProduct;
	dropType?: DropType;
	cardIndex: number;
	page?: number;
	viewType?: Maybe<ProductViewEnum>;
	searchId?: string | null;
	hasRebate?: boolean;
	rebatesOnPage?: boolean;
	isNonstockSearch?: boolean;
	isNewLookAndFeel?: boolean;
	createGTMEvent: UseSearchResultsPayload['createGTMEvent'];
};

const SalesRestriction: FunctionComponent<{ location: LocationFieldsFragment | undefined }> = ({ location }) => {
	if (!location?.state) {
		return null;
	}
	return (
		<div className={`dib pr2`} data-testid="searchProductCardSaleRestriction">
			<WarningIcon className={`f5 v-btm mr1 relative theme-review`} />
			<span>
				Not available in <span className="underline">{location.state.name}</span>
			</span>
		</div>
	);
};

const ShippingInfo: FunctionComponent<{
	isQuickShip: boolean;
	message: string;
	hasSalesRestriction: boolean;
}> = ({ isQuickShip, message, hasSalesRestriction }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isShowingPopover, setIsShowingPopover] = useState(false);

	const handleOnMouseEnter = () => {
		setIsShowingPopover(true);
	};

	const handleOnMouseLeave = () => {
		setIsShowingPopover(false);
	};

	const handleSalesRestrictionClick = () => {
		setIsOpen(!isOpen);
	};

	const location = useCustomer()?.data?.customer?.location;

	if (hasSalesRestriction) {
		const salesRestrictionMessage = <div className="f6">Unable to deliver the product to this area.</div>;
		return (
			<>
				<div className={`dn db-ns`} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
					<Popover
						toggleElement={<SalesRestriction location={location} />}
						isVisible={isShowingPopover}
						setIsVisible={setIsShowingPopover}>
						{salesRestrictionMessage}
					</Popover>
				</div>
				<div>
					<ClickableElement
						className="flex flex-row items-center pr1 dn-ns"
						onClick={handleSalesRestrictionClick}
						onKeyDown={handleSalesRestrictionClick}
						ariaLabel="Sales Restriction Warning">
						<>
							<SalesRestriction location={location} />
							<div className="flex pt1">
								<ChevronDownIcon className={`${isOpen ? 'rotate-180' : ''}`} />
							</div>
						</>
					</ClickableElement>
					<div className={`${isOpen ? '' : 'dn'} pt2`}>{salesRestrictionMessage}</div>
				</div>
			</>
		);
	}
	if (isQuickShip && message) {
		return (
			<div className="b" data-testid="searchProductCardQuickShipInfo">
				<QuickShipIcon className={`f2 mr1 relative ${quickShipIcon} theme-primary omni-theme-secondary`} />
				<span>{message}</span>
			</div>
		);
	}

	return <div className="b">{message}</div>;
};

export type VariantListItemProps = {
	variant: SearchVariantFieldsFragment;
	analyticsEvent?: AnalyticsEvent[];
	serverAnalytics?: ServerAnalyticsInterface;
};

const NonstockBadge: FunctionComponent<{ product: SearchProduct; className?: string }> = ({ product, className = '' }) => {
	const variantCount = product.variants?.length ?? 0;
	const nonstockCount = product.variants?.reduce((count: number, variant) => count + (variant.status !== 'stock' ? 1 : 0), 0) ?? 0;
	if (nonstockCount === 0) {
		return null;
	}
	return (
		<div className={`dib ph2 pv1 bg-theme-internal theme-white f6 ${className}`}>
			{nonstockCount < variantCount && <>{nonstockCount}</>} Nonstock
		</div>
	);
};

export type VariantListProps = {
	variants: SearchVariantFieldsFragment[];
	currentVariant: SearchVariantFieldsFragment;
	dropType: DropType;
	cardIndex: number;
	product: SearchProduct;
	findingMethodEvent?: GTMFindingMethodEvent | null;
	serverAnalytics?: ServerAnalyticsInterface;
	createGTMEvent: UseSearchResultsPayload['createGTMEvent'];
	isTileView: boolean;
};

const VariantList: FunctionComponent<VariantListProps> = ({ variants, currentVariant, product, isTileView }) => {
	return (
		<>
			<div className={`mv2 h2-ns ${isTileView ? 'flex-ns items-center-ns' : 'dn-ns'}`} data-testid="searchProductCardVariants">
				{variants?.length > 1 && <SearchVariantSelector variants={variants} maxNumberToDisplay={5} isTileView={isTileView} />}
				<div className={`tl theme-grey f7 ${variants?.length === 1 ? '' : 'dn-ns'} pt1-l`}>
					Color: <span className="b">{currentVariant.name}</span>
				</div>
			</div>
			<div className={`center dn ${isTileView ? '' : 'db-ns'}`}>
				<CompareButton variantId={product.id} hasBorder={false} className="pa3 underline-hover" />
			</div>
		</>
	);
};

const ProductAttributes: FunctionComponent<{ product: SearchProduct }> = ({ product }) => (
	<div className={'dn db-ns w-25 pa2 bg-theme-grey-lighter'} data-testid="searchProductCardAttributes">
		<ul className={'f6 mv0 pl4'}>
			{product.collection && (
				<li className="pa1">
					<Link url={product.collection.url} color={'grey-darker'} underline={true}>
						{product.collection.name} Collection
					</Link>
				</li>
			)}
			{product.parts?.url && (
				<li className="pa1">
					<Link url={product.parts.url} color={'grey-darker'} underline={true}>
						{product.parts.name}
					</Link>
				</li>
			)}
			{product.attributes &&
				product.attributes.map((attr, index) => (
					<li className={'pa1 theme-grey-darker'} key={`${attr.name}:${index}`}>
						{attr.name}
						{attr.values && `: ${attr.values}`}
					</li>
				))}
		</ul>
	</div>
);
type ManagedPurchaseDisplayPriceProps = {
	price: number;
	variant: SearchVariantFieldsFragment;
	priceUnits?: string;
	startingAt?: boolean;
};

const ManagedPurchaseDisplayPrice: FunctionComponent<ManagedPurchaseDisplayPriceProps> = ({ variant, price, priceUnits, startingAt }) => {
	return variant.productPricingDisplay === 'STANDARD' || variant.productPricingDisplay === 'CALL_FOR_PURCHASE' ? (
		<SearchProductCardPrice price={price} units={priceUnits} startingAt={startingAt} />
	) : (
		<span className="b">Contact for Pricing</span>
	);
};

type SearchProductCardPriceContainerProps = {
	variant: SearchVariantFieldsFragment;
	minPrice: number;
	priceUnits: string | undefined;
	showPriceStartingAt: boolean;
};

const SearchProductCardPriceContainer: FunctionComponent<SearchProductCardPriceContainerProps> = ({ variant, priceUnits }) => {
	const isManagedPurchaseDisplayActive = useFeature(FEATURE_FLAGS.MANAGED_PURCHASE_DISPLAY);

	let price;

	if (variant.priceInfo.unitPrice) {
		price = variant.priceInfo.unitPrice.price;
	} else {
		price = variant.priceInfo.current;
	}

	return isManagedPurchaseDisplayActive ? (
		<ManagedPurchaseDisplayPrice variant={variant} price={price} priceUnits={priceUnits} startingAt={false} />
	) : (
		<SearchProductCardPrice price={price} units={priceUnits} startingAt={false} />
	);
};

export type PriceRatingAndShippingProps = {
	product: SearchProduct;
	minPrice: number;
	variant: SearchVariantFieldsFragment;
	priceUnits: string | undefined;
	showPriceStartingAt: boolean;
	isNewLookAndFeel: boolean;
	rebatesOnPage: boolean | undefined;
	hasRebate: boolean | undefined;
	url: string;
	isListView: boolean;
	variants: SearchVariantFieldsFragment[];
	hasSalesRestrictions: boolean;
};

const PriceRatingAndShipping: FunctionComponent<PriceRatingAndShippingProps> = ({
	product,
	minPrice,
	variant,
	priceUnits,
	showPriceStartingAt,
	isNewLookAndFeel,
	rebatesOnPage,
	hasRebate,
	url,
	isListView,
	variants,
	hasSalesRestrictions
}) => {
	return (
		<div className={'flex flex-column items-stretch lh-title mt1'} data-testid="search-result-price-rating-shipping">
			{!isNewLookAndFeel && rebatesOnPage ? (
				<div className={'flex min-h1 mb1'} style={{ height: '24px' }}>
					{hasRebate ? (
						<div className="f7 pa1 br2 b" style={{ height: '24px', backgroundColor: '#DFF0D8', color: '#157A46' }}>
							Rebate
						</div>
					) : null}
				</div>
			) : null}
			<SearchProductCardPriceContainer
				variant={variant}
				minPrice={minPrice}
				priceUnits={priceUnits}
				showPriceStartingAt={showPriceStartingAt}
			/>
			<div className={'flex flex-wrap min-h1 mt1'} data-testid="searchProductCardRating">
				{product.rating?.ratingValue > 0 ? (
					<Rating
						ratingValue={product.rating.ratingValue}
						url={`${url}#reviews`}
						reviewCount={product.rating.reviewCount}
						abbreviatedReviewCount={true}
					/>
				) : null}
			</div>
			{!isNewLookAndFeel && isListView && (
				<div className="pt3 dn db-ns" data-testid="searchProductCardVariantsList">
					<SearchVariantSelector variants={variants} maxNumberToDisplay={6} isTileView={!isListView} />
					<div className={`tl theme-grey f7 ${variants?.length === 1 ? '' : 'dn-ns'} pt1`}>
						Color: <span className="b">{variant.name}</span>
					</div>
				</div>
			)}

			{!isNewLookAndFeel && (product.shippingInfo?.isQuickShip || product.shippingInfo?.message || hasSalesRestrictions) ? (
				<div className={'mt3 h2 f6 mb2 mb0-l'}>
					<ShippingInfo
						hasSalesRestriction={hasSalesRestrictions}
						isQuickShip={Boolean(variant.isQuickShip)}
						message={variant?.estimatedDeliveryMessage || ''}
					/>
				</div>
			) : null}
		</div>
	);
};
const AddToCartSection: FunctionComponent<{
	handleAddToCartClick: any;
	isConfigurable: boolean;
	hasSalesRestrictions: boolean;
	isShowroomOnlyProduct: boolean;
	loading: boolean;
	busyText?: string;
	variant: SearchVariantFieldsFragment;
}> = ({ handleAddToCartClick, isShowroomOnlyProduct, isConfigurable, hasSalesRestrictions, loading, busyText, variant }) => {
	const { addToCartActionStatus } = useAddToCartButton({ pageName: 'PLP' });
	const isManagedPurchaseDisplayActive = useFeature(FEATURE_FLAGS.MANAGED_PURCHASE_DISPLAY);
	const buttonType = isManagedPurchaseDisplayActive ? variant.productPricingDisplay : 'STANDARD';
	return (
		<AddToCartButtonFactory
			buttonType={buttonType}
			addToCartButtonType={ADD_TO_CART_BUTTON_TYPE.PLP}
			handleOnClick={handleAddToCartClick}
			disabled={false}
			status={addToCartActionStatus}
			isConfigurable={isConfigurable}
			hasSalesRestrictions={hasSalesRestrictions}
			isShowroomOnlyProduct={isShowroomOnlyProduct}
			isBusy={loading}
			busyText={busyText}
		/>
	);
};

export const SearchProductCard: FunctionComponent<SearchProductCardProps> = ({
	product,
	dropType = 'search',
	viewType = 'TILE',
	cardIndex,
	page,
	searchId,
	hasRebate,
	rebatesOnPage,
	isNonstockSearch = false,
	isNewLookAndFeel = false,
	createGTMEvent
}) => {
	const { addToCart, status } = useAddToCartAction();

	const navigate = useNavigation();

	const { hoveredVariant, selectedVariant } = useContext(SearchVariantContext);
	const variant = hoveredVariant || selectedVariant;

	const isManagedPurchaseDisplayActive = useFeature(FEATURE_FLAGS.MANAGED_PURCHASE_DISPLAY);

	const [isBottomDrawerOpen, setIsBottomDrawerOpen] = useState(false);

	const { notifySuccess, notifyDanger } = useNotification();

	const { id, brandName, title, modelNumber, attributes, familyId, priceInfo, variants, isConfigurable } = product;

	const isTileView = viewType === 'TILE';
	const isListView = !isTileView;

	const imageVariations = [
		{
			options: {
				width: 160,
				height: 160
			},
			mediaCondition: {
				maxWidth: 30,
				unit: 'em' as const
			}
		}
	];

	const { min, max } = priceInfo.unitPrice?.range || priceInfo.range || {};
	const minPrice = min || 0;
	const maxPrice = max || 0;
	const showPriceStartingAt = minPrice < maxPrice;
	const priceUnits = priceInfo.unitPrice?.units;
	const hasSalesRestriction = variant?.isProductRestricted;
	// When productionalizing Managed Purchase Display, remove this and use variant.productPricingDisplay === 'SHOW_ROOM_ONLY' directly
	const isShowroomOnlyProduct = isAppointmentOnlySearchProduct(product, isManagedPurchaseDisplayActive);

	let serverAnalytics: ServerAnalyticsInterface<TrackSearchResultClickMutation, TrackSearchResultClickMutationVariables> | undefined;
	if (searchId) {
		serverAnalytics = {
			mutation: TRACK_SEARCH_RESULT_CLICK,
			variables: { input: { searchId, familyId, uniqueId: variant.id, page: page ?? 1, pagePosition: cardIndex } }
		};
	}
	const { addImpression } = useContext(ImpressionsContext);
	const { ref, inView } = useInView({
		triggerOnce: true
	});

	useEffect(() => {
		if (inView) {
			addImpression(product, cardIndex + 1);
		}
	}, [addImpression, inView, product, cardIndex]);

	const productClickEvent = createGTMEvent(TrackedEvent.PRODUCT_CLICK, { product, dropType, cardIndex });
	const findingMethodEvent = getFindingMethodEventByDropType(dropType);
	const analyticsEvent = createAnalyticsEvents(productClickEvent, findingMethodEvent);
	const detailsSectionClassName = isTileView ? `w-100 h-100 h-50-l ${isNonstockSearch ? 'pb3-ns' : 'pa0-ns'}` : 'w-50';

	let url = searchId ? setUrlParam(variant.url, 'searchId', searchId) : variant.url;
	url = isNonstockSearch ? setUrlParam(url, 'ns', 'true') : url;

	const analyticsData = {
		variantId: variant.id,
		pageName: ANALYTICS_PAGE_NAMES.PRODUCT_LISTINGS_PAGE,
		link: 'Add to Cart',
		linkName: 'Add to Cart',
		parentId: product.familyId
	};

	const handleAddToCartClick = () => {
		if (isConfigurable) {
			createGTMEvent(TrackedEvent.PLP_CLICKED_CONFIGURE);
		}
		if (isConfigurable || hasSalesRestriction || isShowroomOnlyProduct) {
			navigate(variant.url);
			return;
		}
		addToCart({ variantId: variant.id, quantity: 1, analyticsData })
			.then(() => {
				notifySuccess('Item successfully added to cart!', 5000);
			})
			.catch(() => {
				notifyDanger('There was an issue adding the item to the cart.', 5000);
			});
	};

	return (
		<div
			ref={ref}
			className={`${
				isTileView
					? `w-50 w-25-ns pv1 ${cardIndex % 2 === 0 ? 'pr1' : 'pl1'} pa2-ns mb3-ns`
					: 'mt2 mt3-ns ba bl-0-ns br-0-ns bt-0-ns br2 br0-ns w-100'
			} bg-theme-white bb-ns b--theme-grey-light relative`}
			data-automation={`product-card-${modelNumber.toLowerCase()}`}
			onMouseEnter={() => {
				setIsBottomDrawerOpen(true);
			}}
			onMouseLeave={() => {
				setIsBottomDrawerOpen(false);
			}}
			data-testid="searchProductCard">
			<div
				className={`flex ${
					isTileView ? `relative flex-column pa2 pa0-ns ba b--theme-grey-light br2 b--none-ns ${cardShadow}` : 'flex-row w-100'
				} h-100`}>
				{/* Image and Finishes section*/}
				<div className={`${isListView ? 'w-50 w-25-ns ph2 pr3-ns pv2 tc' : ''}`}>
					<Link url={url} className="db center mw5" serverAnalytics={serverAnalytics} analyticsEvent={analyticsEvent}>
						<div className="aspect-ratio aspect-ratio--1x1 tc">
							<div className="aspect-ratio--object z-1">
								<CloudinaryImage
									publicID={variant.image.id.toString()}
									description={variant.image.description}
									options={{ height: 214, width: 214 }}
									useDimensions={false}
									variations={imageVariations}
									bypassLazyLoad={cardIndex < 8}
								/>
							</div>
						</div>
					</Link>
					<VariantList
						variants={variants}
						product={product}
						currentVariant={variant}
						dropType={dropType}
						cardIndex={cardIndex}
						findingMethodEvent={findingMethodEvent}
						serverAnalytics={serverAnalytics}
						createGTMEvent={createGTMEvent}
						isTileView={isTileView}
					/>
				</div>
				{/* Details section*/}
				<div className={`${detailsSectionClassName}`}>
					<div className={`flex flex-column justify-between w-100 h-100`}>
						<div className={isListView ? 'pl3 pr2 pt2' : ''}>
							{/* Brand and Model Number*/}
							<div className={'w-100 overflow-hidden'} style={isListView ? {} : { height: '5.3rem' }}>
								<Link
									url={url}
									color="grey-darker"
									automationHook="product-card-description"
									serverAnalytics={serverAnalytics}
									analyticsEvent={analyticsEvent}>
									<div
										className={`hover-theme-primary lh-title normal f5 fw3 fw2-ns ${
											isTileView ? 'tc3-title tc4-title-ns' : ''
										}`}
										data-testid="searchProductCardBrand">
										<span className="fw6">{brandName}</span> {title}
									</div>
								</Link>
								<div className={'f6 mt1 lh-title theme-grey truncate'} data-testid="searchProductCardModel">
									Model: <b>{variant.modelNumber}</b>
								</div>
							</div>
							{isListView && isNonstockSearch && <NonstockBadge product={product} className="mt2 mb1" />}
							{/* Price, Rating, and Shipping Info */}
							{!isShowroomOnlyProduct && (
								<PriceRatingAndShipping
									product={product}
									variant={variant}
									minPrice={minPrice}
									priceUnits={priceUnits}
									showPriceStartingAt={showPriceStartingAt}
									isNewLookAndFeel={isNewLookAndFeel}
									rebatesOnPage={rebatesOnPage}
									hasRebate={hasRebate}
									url={url}
									isListView={isListView}
									variants={variants}
									hasSalesRestrictions={hasSalesRestriction}
								/>
							)}
						</div>
						{!isNewLookAndFeel && isListView && (
							<div className="dn db-ns">
								<div className="pl3 pb3">
									<div className="w5 pt2">
										<AddToCartSection
											handleAddToCartClick={handleAddToCartClick}
											hasSalesRestrictions={hasSalesRestriction}
											isShowroomOnlyProduct={isShowroomOnlyProduct}
											variant={variant}
											isConfigurable={Boolean(isConfigurable)}
											loading={status.loading}
										/>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				{/* Product Attributes; hidden on mobile */}
				{isListView && attributes && attributes.length > 0 && <ProductAttributes product={product} />}
				{isTileView && isNonstockSearch && (
					<NonstockBadge product={product} className="z-1 absolute top-0 right-0 top--1-ns disableClickEvents" />
				)}
				{!isNonstockSearch && isTileView && !isNewLookAndFeel && (
					<div className={`w-100 mt2 dn-l`}>
						<AddToCartSection
							handleAddToCartClick={handleAddToCartClick}
							variant={variant}
							isConfigurable={Boolean(isConfigurable)}
							isShowroomOnlyProduct={isShowroomOnlyProduct}
							hasSalesRestrictions={hasSalesRestriction}
							loading={status.loading}
							busyText={'Adding...'}
						/>
						<div className={`self-end tc`}>
							<CompareButton variantId={id} hasBorder={false} className="pa3 underline-hover mt2-ns" />
						</div>
					</div>
				)}
			</div>
			{/* Section only shows in mobile List View */}
			{!isNonstockSearch && isListView && (
				<div className="dn-ns">
					<div className="flex justify-center">
						<div className="w-100 mw5 mt2 ph2">
							<AddToCartSection
								handleAddToCartClick={handleAddToCartClick}
								variant={variant}
								hasSalesRestrictions={hasSalesRestriction}
								isConfigurable={Boolean(isConfigurable)}
								loading={status.loading}
								isShowroomOnlyProduct={isShowroomOnlyProduct}
							/>
						</div>
					</div>
					<div className={`flex justify-center mb2`}>
						<CompareButton variantId={id} hasBorder={false} className="pa3 pb2 underline-hover" />
					</div>
				</div>
			)}
			{isTileView && isBottomDrawerOpen ? (
				<div
					className={`z-2 bb br bl b--theme-grey-light bg-theme-white left-0 right-0 w-auto absolute mt2 dn db-l`}
					style={{ boxShadow: '0px 10px 10px -10px rgba( 0, 0, 0, 0.2 )' }}>
					<div className="w-100 pa2 pt0" data-testid="bottom-add-to-cart-drawer">
						<AddToCartSection
							handleAddToCartClick={handleAddToCartClick}
							variant={variant}
							isShowroomOnlyProduct={isShowroomOnlyProduct}
							isConfigurable={Boolean(isConfigurable)}
							loading={status.loading}
							hasSalesRestrictions={hasSalesRestriction}
						/>
						{!isNonstockSearch && (
							<div className={'flex justify-center pv1'}>
								<CompareButton variantId={id} hasBorder={false} className="pa2 pb0 underline-hover" />
							</div>
						)}
					</div>
				</div>
			) : null}
		</div>
	);
};
